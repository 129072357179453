/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, Container, Grid, Card, CardContent, CardMedia, makeStyles, colors } from '@material-ui/core';
import { useLanguage } from '../contexts/LanguageContext';
import { useTheme } from '../contexts/ThemeContext';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.type === 'dark' ? '#202020' : '#fff',
    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.7)',
    boxShadow: theme.palette.type === 'dark' ? '0 4px 8px rgba(0, 0, 0, 0.3)' : '0 4px 6px rgba(0, 0, 0, 0.06)',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardMedia: {
    height: 240,
  },
  cusTitle: {
    fontWeight: '500', // 设置为粗体
    color: theme.palette.type === 'dark' ? '#435AD7' : '#3F51B5'
  },
}));

function Services() {
  const { language } = useLanguage();
  const { isDarkMode } = useTheme();
  const classes = useStyles();

  const texts = {
    zh: {
      title: '我们的优势',
      services: [
        { title:'产品面向市场', 
          description: (
            <div>
              <Typography variant="body1" style={{ fontWeight: 'normal' , marginTop: '16px' }}>1.为所有使用场景而设计</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal',color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)', marginBottom: '16px'}}> 包含租船、运营、航次优化、燃油测算等。</Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal' }}>2.完整和标准化的海洋数据库</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' }}> 包含航线、港口、导航、位置、全球气象数据和船舶性能测算等。</Typography>
            </div>
          ), image: '/images/market.jpg'
        },
        { title: '拥有领先技术', 
          description: (
            <div>
              <Typography variant="body1" style={{ fontWeight: 'normal', marginTop: '16px'  }}>1.独家技术</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' , marginBottom: '16px' }}>包含风浪速降低模型、性能模型和航线测算模型等。</Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal' }}>2.领先技术</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)',marginBottom:'20px'  }}>包含船舶定位、导航模型和全球天气风险评估等。</Typography>
            </div>
          ), image: '/images/Technology.jpg'
        },
        { title: '经验丰富的团队', 
          description: (
            <div>
              <Typography variant="body1" style={{ fontWeight: 'normal' , marginTop: '16px' }}>1.拥有10年的行业经验</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' , marginBottom: '16px'}}>为200多名客户和400名可接触的潜在客户提供服务。</Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal' }}>2.核心团队拥有6年以上合作经验</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.4)'}}>团队从2018年开始初始项目，在处理海事行业的各种项目方面经验丰富。</Typography>
            </div>  
          ), image: '/images/team.jpg'
        },
      ],
    },
    en: {
      title: 'OUR ADVANTAGES',
      services: [
        { title:'To Market', 
          description: (
            <div>
              <Typography variant="body1" style={{ fontWeight: 'normal' , marginTop: '16px' }}>1.Product Designed for Full Use Case</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal',color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)', marginBottom: '16px'}}>Covering Chartering, Operation, Voyage Optimization, Bunker etc.</Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal' }}>2.Completed and Standardized Marine Database</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' }}>Covering Routes, Ports, Navigation, Position, Global Weather Data and Vessel Performance etc.</Typography>
            </div>
          ), image: '/images/market.jpg'
        },
        { title: 'Leading Technology', 
          description: (
            <div>
              <Typography variant="body1" style={{ fontWeight: 'normal', marginTop: '16px'  }}>1.Exclusive Technology</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' , marginBottom: '16px' }}>Covering Wind-Wave-Speed Reduction Model, Performance Model and Routing Model etc.</Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal' }}>2.Advanced Technology</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' }}>Covering Vessel Positioning, Navigation Model and Global Weather Risk Evaluation etc.</Typography>
            </div>
          ), image: '/images/Technology.jpg'
        },
        { title: 'Experienced team', 
          description: (
            <div>
              <Typography variant="body1" style={{ fontWeight: 'normal' , marginTop: '16px' }}>1.About 10 Years Industry Experience</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)' , marginBottom: '16px'}}>Serving More Than 200 Customers and 400 Reachable Potential Customers</Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal' }}>2.Core team with 6+ years of collaboration</Typography>
              <Typography variant="body2" style={{ fontWeight: 'normal', color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.4)' }}>Team Started the Initial Project from 2018, experienced in handling various projects in Maritime Industry.</Typography>
            </div>  
          ), image: '/images/team.jpg'
        },
      ],
    },
  };

  const t = texts[language];

  return (
    <Container id="services" className="services">
      <Typography variant="h4" gutterBottom>{t.title}</Typography>
      <Grid container spacing={3}>
        {t.services.map((service, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card className={classes.card} style={{ borderRadius:'6px' }} >
              <CardMedia
                className={classes.cardMedia}
                image={service.image}
                title={service.title}
              />
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" gutterBottom className={classes.cusTitle}>{service.title}</Typography>
                <Typography>{service.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Services;