/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, Container, Grid, TextField, Button } from '@material-ui/core';
import { useLanguage } from '../contexts/LanguageContext';

function Contact() {
  const { language } = useLanguage();

  const handleSubmit = (event) => {
    event.preventDefault();
    // 这里添加表单提交逻辑
  };

  const texts = {
    zh: {
      title: '联系我们',
      name: '姓名',
      email: '邮箱',
      message: '信息',
      submit: '提交',
      // contactInfo: '联系方式',
      address: '地址：深圳市宝安区卓越时代广场A座',
      emailAddress: '邮箱：service@fltins.com',
    },
    en: {
      title: 'CONTACT US',
      name: 'Name',
      email: 'Email',
      message: 'Message',
      submit: 'Submit',
      // contactInfo: 'Contact Information',
      address: 'Address: Block A, Excellence Times Square, Bao\'an District, Shenzhen',
      emailAddress: 'Email: service@fltins.com',
    },
  };

  const t = texts[language];

  return (
    <Container id="contact" className="contact">
      <Typography variant="h4" gutterBottom>{t.title}</Typography>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <TextField fullWidth margin="normal" label={t.name} variant="outlined" required />
            <TextField fullWidth margin="normal" label={t.email} variant="outlined" required type="email" />
            <TextField fullWidth margin="normal" label={t.message} variant="outlined" required multiline rows={4} />
            <Button type="submit" variant="contained" color="primary">{t.submit}</Button>
          </form>
        </Grid> */}
        <Grid item xs={12} md={6}>
          {/* <Typography variant="h6" gutterBottom>{t.contactInfo}</Typography> */}
          <Typography>{t.address}</Typography>
          <Typography>{t.phone}</Typography>
          <Typography>{t.emailAddress}</Typography>
          {/* 这里可以添加社交媒体图标 */}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;