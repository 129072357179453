import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { useLanguage } from '../contexts/LanguageContext';
import { useTheme } from '../contexts/ThemeContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    transition: 'all 0.3s ease',
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  colored: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    backdropFilter: 'blur(10px)',
    boxShadow: 'none',
    WebkitBackdropFilter: 'blur(10px)', // 为 Safari 浏览器添加前缀
  },
  logo: {
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navItems: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  navButton: {
    marginLeft: theme.spacing(2),
  },
  hidden: {
    transform: 'translateY(-100%)',
    transition: 'transform 0.3s ease',
  },
  visible: {
    transform: 'translateY(0)',
    transition: 'transform 0.3s ease',
  },
}));

function Navbar({ scrollToSection }) {
  const classes = useStyles();
  const { language, toggleLanguage } = useLanguage();
  const { isDarkMode, toggleTheme } = useTheme();
  const [textColor, setTextColor] = useState('#000');
  const [isTransparent, setIsTransparent] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const navbarRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight * 0.1; // 10% of viewport height

      if (scrollPosition > threshold) {
        setIsTransparent(false);
        setTextColor('#fff');
      } else {
        setIsTransparent(true);
        updateTextColor();
      }

      setIsVisible(false);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
      scrollTimeout.current = setTimeout(() => {
        setIsVisible(true);
      }, 150); // 150ms 后显示导航栏
    };

    const updateTextColor = () => {
      if (navbarRef.current) {
        const rect = navbarRef.current.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.bottom + 1;

        const element = document.elementFromPoint(x, y);
        if (element) {
          const bgColor = window.getComputedStyle(element).backgroundColor;
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTextColor(brightness > 128 ? '#000' : '#fff');
          }
        }
      }
    };

    handleScroll(); // 初始检查
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateTextColor);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateTextColor);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  const texts = {
    zh: {
      home: '首页',
      about: '关于我们',
      services: '服务',
      team: '团队',
      contact: '联系我们',
      toggleTheme: '切换主题',
    },
    en: {
      home: 'Home',
      about: 'About',
      services: 'Services',
      team: 'Team',
      contact: 'Contact',
      toggleTheme: 'Toggle Theme',
    },
  };

  const t = texts[language];

  return (
    <AppBar 
      position="fixed" 
      className={`${classes.root} ${isTransparent ? classes.transparent : classes.colored} ${isVisible ? classes.visible : classes.hidden}`} 
      ref={navbarRef}
    >
      <Toolbar>
        <Typography variant="h6" className={classes.logo}>
          <img src={'/images/logo.png'} alt="logo" style={{ width: '180px', height: 'auto' }} />
        </Typography>

        <div className={classes.navItems}>
          <Button className={classes.navButton} onClick={() => scrollToSection('home')} style={{ color: textColor }}>{t.home}</Button>
          {/* <Button className={classes.navButton} onClick={() => scrollToSection('about')} style={{ color: textColor }}>{t.about}</Button> */}
          {/* <Button className={classes.navButton} onClick={() => scrollToSection('team')} style={{ color: textColor }}>{t.team}</Button> */}
          <Button className={classes.navButton} onClick={() => scrollToSection('services')} style={{ color: textColor }}>{t.services}</Button>
          <Button className={classes.navButton} onClick={() => scrollToSection('contact')} style={{ color: textColor }}>{t.contact}</Button>
          <Button className={classes.navButton} onClick={toggleLanguage} style={{ color: textColor }}>
            {language === 'zh' ? 'EN' : '中文'}
          </Button>
          <IconButton onClick={toggleTheme} aria-label={t.toggleTheme} style={{ color: textColor }}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;