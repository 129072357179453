import React from 'react';
// import Slider from 'react-slick';
import { Container, Typography } from '@material-ui/core';
import { useLanguage } from '../contexts/LanguageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { useTheme as useCustomTheme } from '../contexts/ThemeContext';


const LogoContainer = styled.div`
  height: 100%;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#000000' : '#ffffff')};
  box-shadow: ${({ isDarkMode }) => (isDarkMode ? '0 4px 8px rgba(0, 0, 0, 0.3)' : '0 2px 6px rgba(0, 0, 0, 0.06)')};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;


function Customers() {
  const { language } = useLanguage();
  const{ isDarkMode } = useCustomTheme();
  
  console.log('isDarkMode:', isDarkMode);

  const logos = [
    { light: '/images/logo1-light.png', dark: '/images/logo1-dark.png' },
    { light: '/images/logo2-light.png', dark: '/images/logo2-dark.png' },
    { light: '/images/logo3-light.png', dark: '/images/logo3-dark.png' },
    { light: '/images/logo4-light.png', dark: '/images/logo4-dark.png' },
    { light: '/images/logo5-light.png', dark: '/images/logo5-dark.png' },
    { light: '/images/logo6-light.png', dark: '/images/logo6-dark.png' },
    { light: '/images/logo7-light.png', dark: '/images/logo7-dark.png' },
    { light: '/images/logo8-light.png', dark: '/images/logo8-dark.png' },
    { light: '/images/logo9-light.png', dark: '/images/logo9-dark.png' },
    { light: '/images/logo10-light.png', dark: '/images/logo10-dark.png' },
    { light: '/images/logo11-light.png', dark: '/images/logo11-dark.png' },
    { light: '/images/logo12-light.png', dark: '/images/logo12-dark.png' },
    { light: '/images/logo13-light.png', dark: '/images/logo13-dark.png' },
    { light: '/images/logo14-light.png', dark: '/images/logo14-dark.png' },
    { light: '/images/logo15-light.png', dark: '/images/logo15-dark.png' },
    { light: '/images/logo16-light.png', dark: '/images/logo16-dark.png' },
    { light: '/images/logo17-light.png', dark: '/images/logo17-dark.png' },
    { light: '/images/logo18-light.png', dark: '/images/logo18-dark.png' },
    { light: '/images/logo19-light.png', dark: '/images/logo19-dark.png' },
    { light: '/images/logo20-light.png', dark: '/images/logo20-dark.png' },
  ];

  const texts = {
    zh: '我们的客户',
    en: 'OUR CUSTOMER',
  };

  return (
    <Container id="about" className="about" style={{ overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom>{texts[language]}</Typography>
      <Grid container spacing={1}>
        {logos.map((logo, index) => (
          <Grid item xs={3} key={index}>
            <LogoContainer isDarkMode={isDarkMode}>
              <img 
              key={isDarkMode ? 'dark':'light'}
              src={isDarkMode ? logo.dark : logo.light} 
              alt={`Logo ${index + 1}`} 
              style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </LogoContainer>
          </Grid>
        ))}
      </Grid>
      
    </Container>
  );
}

export default Customers;