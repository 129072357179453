import React, { useRef } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { LanguageProvider } from './contexts/LanguageContext';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Customers from './components/Customers';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(4, 0),
  },
}));

function AppContent() {
  const { isDarkMode } = useTheme();
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      type: isDarkMode ? 'dark' : 'light',
      background: {
        default: isDarkMode ? '#121212' : '#fafafa',
      },
    },
  });

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (sectionId) => {
    const sectionRefs = {
      home: homeRef,
      about: aboutRef,
      services: servicesRef,
      contact: contactRef
    };
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Navbar scrollToSection={scrollToSection} />
        <main className={classes.main}>
          <div ref={homeRef}><Home /></div>
          <div ref={servicesRef} className={classes.section}><Services /></div>
          <div ref={aboutRef} className={classes.section}><Customers /></div>
          <div ref={contactRef} className={classes.section}><Contact /></div>
        </main>
        <Footer />
      </div>
    </MuiThemeProvider>
  );
}

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;