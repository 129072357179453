/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useLanguage } from '../contexts/LanguageContext';
import { makeStyles } from '@material-ui/core/styles';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Tooltip from '@mui/material/Tooltip';
import QRCode from '../images/QRCode.png'; // 替换为您的二维码图像路径
// 添加样式
const useStyles = makeStyles((theme) => ({
  homeContainer: {
    height: '100vh', // 保持不变
    width: '100%',
    padding: 0,
    margin:  '-64px 0 0 0', // 移除顶部的负边距
    position: 'relative',
    overflow: 'hidden',
    display: 'flex', // 添加 flex 布局
    flexDirection: 'column', // 垂直方向排列
  },
  videoBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -2,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.45)', // 30%透明度的黑色
    zIndex: -1, // 确保在视频之上，但在内容之下
  },
  content: {
    position: 'absolute', // 改为绝对定位
    top: '60%',
    left: '10%', // 调整左侧位置
    transform: 'translateY(-50%)', // 只在垂直方向上居中
    zIndex: 1,
    color: '#fff',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    textAlign: 'left', // 文字左对齐
    width: '80%', // 调整宽度以避免文字靠近右边缘
  },
  customSubtitle: {
    // fontStyle: 'italic', // 斜体
    color: '#FFFFFF', // 自定义颜色
    fontSize: '4rem', // 自定义字体大小
    // opacity: 0.8, // 设置透明度
    fontWeight: 'bold', // 设置字体加粗
    lineHeight: '1.4', // 设置行高
  },
  title: {
    // fontStyle: 'italic', // 斜体
    color: '#FFFFFF', // 自定义颜色
    fontSize: '2.5rem', // 自定义字体大小
    // opacity: 0.9, // 设置透明度
    fontWeight: 'medium', // 设置字体加粗
  },
}));

function Home() {
  const { language } = useLanguage();
  const classes = useStyles();

  const texts = {
    zh: {
      title: <Typography variant="h4" gutterBottom className={classes.title}><span style={{color: '#FF8600',fontStyle: 'italic',fontSize: '4rem',marginRight:'14px'}}>Fleet Insight</span> Platform</Typography>,
      subtitle: '更好的商业绩效，更少的二氧化碳排放',
      cta: '安卓版下载',
      newButton: 'iOS版下载' // 新增按钮文本
    },
    en: {
      title: <Typography variant="h4" gutterBottom className={classes.title}><span style={{color: '#FF8600',fontStyle: 'italic',fontSize: '4rem',marginRight:'14px'}}>Fleet Insight</span> Platform</Typography>,
      subtitle: 'Better Business Performance, Less CO₂ Emissions',
      cta: 'Android Download',
      newButton: 'iOS Download' // 新增按钮文本
    },
  };

  const t = texts[language];

  return (
    <div id="home" className={classes.homeContainer}>
      <video
        className={classes.videoBackground}
        autoPlay
        loop
        muted
        playsInline
        poster="/videos/cover-image.png" // 添加封面图像路径
        onError={(e) => {
          console.error('视频加载错误:', e);
          console.error('错误代码:', e.target.error ? e.target.error.code : '未知');
          console.error('错误消息:', e.target.error ? e.target.error.message : '未知');
        }}
        onLoadedData={() => console.log('视频加载成功')}
      >
        <source src="/videos/backgorund.mp4" type="video/mp4" />
        您的浏览器不支持视频标签。
      </video>
      <div className={classes.overlay}></div>
      <div className={classes.content}>
        <Typography variant="h4" gutterBottom className={classes.title}>{t.title}</Typography>
        <Typography variant="h1" gutterBottom className={classes.customSubtitle}>{t.subtitle}</Typography>
        <div>
          <a href="/videos/backgorund.mp4" download> {/*替换为实际的android应用下载链接*/}
          <Button variant="contained" color="primary" style={{marginRight: '10px', textTransform: 'none'}} startIcon={<AndroidIcon />}>{t.cta}</Button>
          </a>
          <Tooltip 
            title={
              <div style={{ textAlign: 'center' }}> 
                <img src={QRCode} alt="二维码" style={{ width: '100px', height: '100px' }} />
                <p>Scan to download</p>
              </div>
            } 
            arrow 
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'white', // 设置背景颜色
                  color: 'black', // 设置文本颜色
                },
              },
            }}
          >
            <a href="https://baidu.com" target="_blank" rel="noopener noreferrer"> {/* 替换为实际的网址 */}
            <Button 
              variant="contained" 
              color="primary" 
              style={{ textTransform: 'none' }} 
              startIcon={<AppleIcon />}
            >
              {t.newButton}
            </Button>
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default Home;